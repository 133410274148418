import React from "react"
import "../../styles/components/global/decoration-strip.scss"

const DecoratedImage = ({ children, stripSrc, stripClasses, classes }) => {
  return (
    <div className={classes ? classes : ""} style={{ position: "relative" }}>
      {children}
      <img
        className={`decoration-strip ${stripClasses ? stripClasses : ""}`}
        src={stripSrc}
        alt="twisted strip"
      />
    </div>
  )
}

export default DecoratedImage
