import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from "../components/regions/header"
import Container from "../components/block/container"
import FieldText from "../components/field/fieldText"
import FormEmail from "../components/full/formEmail"
import FieldAccordion from "../components/field/fieldAccordion"
import Accordion from "../components/item/accordion"
import FieldButton from "../components/field/fieldButton"
import BlockInfo from "../components/full/blockInfo"
import BlockClients from "../components/full/blockClients"
import BlockTestimonials from "../components/full/blockTestimonials"
import UseCasesStrip from "../assets/images/twisted-strip--use-cases.png"
import DecoratedImage from "../components/item/decoratedImage"
import "../styles/components/block--page-top-content--home.scss"
import "../styles/components/block--client-requests-workflow.scss"
import "../styles/components/block--try-home.scss"
import "../styles/components/block--requests.scss"
import "../styles/components/block--use-cases-preview.scss"
import "../styles/utilities/utilities.scss"

const IndexPage = () => {
  return (
    <>
      <Header />
      <Layout>
        <Seo title="Home" pageClass="page--front" />

        <Container classes="block--page-top-content block--page-top-content--home">
          <FieldText>
            <h1 className="text text--centered">
              A simple project management app to help you handle incoming
              requests from your clients
            </h1>
            <p className="text text--centered">
              Sideline helps creative teams and agencies easily manage inbound
              project requests. Ready to boost your team's productivity?
            </p>
          </FieldText>

          <FormEmail />

          <FieldText classes="field--text--sign-up">
            <p className="text text--centered text--smaller">
              See Sideline in action. <Link to="/sign-up">Get a Demo</Link>
            </p>
          </FieldText>

          <Container classes="block--main-image">
            <StaticImage
              as="figure"
              className="field field--image"
              src="../assets/images/requests-01-original-request.png"
              quality="90"
              placeholder="blurred"
              alt="Sideline in action"
              title="Sideline in action"
            />
          </Container>

          <BlockClients />
        </Container>

        <Container
          classes="block--client-requests-workflow"
          title="Creative services teams can quickly become bogged down by one-off requests from clients."
          description="Sideline organizes inbound client requests and helps your team get work done more efficiently than ever before."
        >
          <div className="item item--old-way">
            <StaticImage
              as="figure"
              className="field field--image"
              src="../assets/images/the-old-way.jpg"
              quality="90"
              placeholder="blurred"
              alt="The old way"
            />
            <FieldText>
              <p className="text text--red text--uppercase">
                <strong>The old way</strong>
              </p>
              <h4>Clients email you when they need something</h4>
              <p>
                This system quickly breaks down if you have more than a few
                clients requesting your services on a regular basis.
              </p>
              <ul className="list list--error">
                <li>
                  <strong>Messages get lost/forgotten</strong>
                </li>
                <li>
                  <strong>No status reporting</strong>
                </li>
                <li>
                  <strong>No time tracking</strong>
                </li>
                <li>
                  <strong>Email chains are messy</strong>
                </li>
              </ul>
            </FieldText>
          </div>

          <div className="item item--new-way">
            <StaticImage
              as="figure"
              className="field field--image"
              src="../assets/images/sideline-way.jpg"
              quality="90"
              placeholder="blurred"
              alt="The Sideline way"
            />
            <FieldText>
              <p className="text text--red text--uppercase">
                <strong>The Sideline way</strong>
              </p>
              <h4>
                Clients requests are funneled into an inbound project queue
                which your whole team can see.
              </h4>
              <ul className="list list--success">
                <li>
                  <strong>Inbound requests</strong> are prioritized into a
                  project queue
                </li>
                <li>
                  <strong>Clients know the status</strong> of their requests
                </li>
                <li>
                  <strong>Track</strong> billable hours
                </li>
                <li>
                  <strong>Eliminate</strong> inbox clutter
                </li>
              </ul>
            </FieldText>
          </div>
        </Container>

        <Container classes="block--requests" row={true}>
          <div className="block__column block__column--50">
            <FieldText>
              <h2>
                Sideline helps you collect and prioritize inbound requests
              </h2>
            </FieldText>

            <FieldAccordion>
              <Accordion title="Inbound Requests via email">
                <p>
                  Accept inbound work requests via email, or through a custom
                  intake form.
                </p>
              </Accordion>

              <Accordion title="Requests Sorting">
                <p>
                  Accept inbound work requests via email, or through a custom
                  intake form.
                </p>
              </Accordion>

              <Accordion title="Request Status">
                <p>
                  Accept inbound work requests via email, or through a custom
                  intake form.
                </p>
              </Accordion>
            </FieldAccordion>

            <FieldButton>
              <Link to="/sign-up" className="button button--big button--red">
                Get Started
              </Link>
            </FieldButton>
          </div>

          <div className="block__column block__column--50 hide--mobile">
            <StaticImage
              as="figure"
              className="field field--image"
              src="../assets/images/iStock-953426638.jpg"
              quality="90"
              placeholder="blurred"
              alt="Project meeting"
            />
          </div>
        </Container>

        <BlockInfo />

        <BlockTestimonials />

        <Container classes="block--use-cases-preview">
          <FieldText>
            <h4 className="text text--uppercase text--red">Who are you?</h4>
            <h2>Check out our use cases to learn more:</h2>
            <ul>
              <li>
                <Link to="/use-cases/creative-agencies/">
                  Creative Agencies
                </Link>
              </li>
              <li>
                <Link to="/use-cases/marketing-agencies/">
                  Marketing Agencies
                </Link>
              </li>
              <li>
                <Link to="/use-cases/dev-teams/">Dev Teams</Link>
              </li>
              <li>
                <Link to="/use-cases/in-house-creative-teams/">
                  In-House Creative Teams
                </Link>
              </li>
              <li>
                <Link to="/use-cases/customer-support-teams/">
                  Customer Support Teams
                </Link>
              </li>
            </ul>
          </FieldText>
          <DecoratedImage
            stripSrc={UseCasesStrip}
            stripClasses={"use-cases-strip"}
          >
            <StaticImage
              as="figure"
              className="field field--image"
              src="../assets/images/use-cases.jpg"
              quality="90"
              placeholder="blurred"
              alt="Use cases"
            />
          </DecoratedImage>
        </Container>

        <Container classes="block--try-home">
          <FieldText>
            <h2>Try Sideline today</h2>
            <p>Ready to boost your team's productivity?</p>
            <Link to="#" className="button button--big button--red">
              Schedule a Demo
            </Link>
            <Link
              to="/sign-up"
              className="button button--big button--transparent button--try"
            >
              Try It For Free for 14 Days!
            </Link>
          </FieldText>
          <StaticImage
            as="figure"
            className="field field--image"
            src="../assets/images/try-home.png"
            quality="90"
            placeholder="blurred"
            alt="Use cases"
          />
        </Container>
      </Layout>
    </>
  )
}

export default IndexPage
