import React from "react"
import { navigate } from "gatsby"
import { useGlobalContext } from "../../context/context"
import { useForm } from "react-hook-form"
import { EMAIL_REGEX } from "../../REGEX/REGEX"
import { setLocalStorage } from "../../localStorageService/localStorageService"
import "../../styles/components/form--email.scss"

const FormEmail = () => {
  const { userInfo, setUserInfo } = useGlobalContext()

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
    trigger,
  } = useForm()

  const emailValue = watch("email")

  const formSubmit = (data, _) => {
    let lowerCaseEmail = data.email.toLowerCase()
    setUserInfo({ ...userInfo, email: lowerCaseEmail })
    setLocalStorage("sideline-client-info", {
      ...userInfo,
      email: lowerCaseEmail,
    })
    navigate(`/sign-up`)
    reset()
  }

  return (
    <form
      action={"/sign-up"}
      className="form form--email"
      onSubmit={handleSubmit(formSubmit)}
    >
      <div className="form-item form-item-type--email form-item--work-email">
        <label
          htmlFor="input-full-work-email"
          className="visually-hidden"
          aria-hidden={true}
        >
          Work email
        </label>
        <input
          type="text"
          id="input-full-work-email"
          placeholder="john@email.com"
          autoComplete="off"
          {...register("email", {
            required: "Email is required",
            pattern: {
              value: EMAIL_REGEX,
              message: "Invalid email address",
            },
          })}
          onKeyUp={() => {
            trigger("email")
          }}
        />

        {errors.email ? (
          <small className="text--error">{errors.email.message}</small>
        ) : null}
      </div>

      <div className="form-actions">
        <button
          disabled={!emailValue || errors.email}
          type="submit"
          className="button button--submit"
        >
          Get Started
        </button>
      </div>
    </form>
  )
}

export default FormEmail
